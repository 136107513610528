import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from '../../components/layout';
import Section from '../../components/section';
import Rule from '../../components/rule';

const pageTitle = 'Partners';

const Partners = () => (
  <>
    <GatsbySeo
      language="en"
      title={pageTitle}
      _description="Learn about David Simpson Apps legal policies regarding agreement terms, data security measures and so on."
      openGraph={{
        url: 'https://www.dsapps.dev/partners/',
      }}
    />
    <Layout pageTitle={pageTitle}>
      <Section>
        <section className="body-font animate-underline-anchors">
          <div className="container flex flex-col lg:flex-row py-4 mx-auto ">
            <div className="flex flex-col mb-16 w-full lg:w-2/3 lg:pr-24 md:pr-16 items-start md:text-left md:mb-0">
              <Rule />

              <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-3xl title-font">
                {pageTitle}
              </h1>

              <p>
                As Microsoft's former CEO,{' '}
                <Link
                  target="_blank"
                  to="https://www.youtube.com/watch?v=XxbJw8PrIkc"
                >
                  Steve Ballmer
                </Link>{' '}
                could quite easily have said:
              </p>
              <blockquote>
                <p>Partnerships. Partnerships. Partnerships.</p>
                <p>Partnerships. Partnerships. Partnerships.</p>
              </blockquote>
              <div className="text-lg md:text-xl  lg:text-2xl leading-8">
                <p>
                  We're <em title="Woo! Yeah!">so excited</em> to partner with
                  world leading tech companies to provide transformative
                  solutions that match your business needs.
                </p>
              </div>

              <p>We are partnered with:</p>

              <div className="py-4">
                <Link className="button-primary" to="/partners/monday/">
                  monday.com
                </Link>
                <Link className="button-primary" to="/partners/atlassian/">
                  Atlassian
                </Link>
              </div>
            </div>

            <div className="items-center w-full lg:w-auto md:mt-10">
              <Link
                target="_blank"
                to="https://www.youtube.com/watch?v=XxbJw8PrIkc"
              >
                <img
                  className="w-full sm:w-1/3 lg:w-full rounded"
                  src="/images/gif/developersdevelopersdevelopers.gif"
                  alt="developers developers developers"
                />
              </Link>
            </div>
          </div>
        </section>
      </Section>
    </Layout>
  </>
);

export default Partners;
